module.exports = new Promise((resolve, reject) => {
      // This check makes sure that the code is executed in the browser
      if (typeof window === 'undefined') {
        return reject(new Error('window is not defined'));
      }
      const baseUrl = window.ENV.CREW_SCHEDULER_BASE_URL?.replace('https://', '');
      const slash = window.ENV.CREW_SCHEDULER_BASE_URL?.endsWith('/') ? '' : '/';
      const script = function createScript(remoteUrl, path, resolve, reject) {
    // This function is called when the remote script is loaded
    const handleScriptLoad = () => {
        const proxy = {
            get: request => window[path].get(request),
            init: arg => {
                try {
                    return window[path].init(arg);
                }
                catch (e) {
                    console.error('remote container already initialized');
                }
            },
        };
        resolve(proxy);
    };
    const script = document.createElement('script');
    script.src = remoteUrl;
    script.onload = () => handleScriptLoad(path, resolve);
    script.onerror = () => reject(new Error('Failed to load script'));
    return script;
}(
        'https://template.' + baseUrl + slash + 'remoteEntry.js',
        'template',
        resolve,
        reject
      );
      document.head.appendChild(script);
    });;